import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import ReactMarkdown from "react-markdown";

const DatenschutzPage = ({ data }) => {
  return (
    <div>
      <Layout>
        <div className="container mx-auto prose mt-12">
          <h1>Datenschutz</h1>
          <ReactMarkdown>{data.strapiDatenschutz.content}</ReactMarkdown>
        </div>
      </Layout>
    </div>
  );
};

export const queryDatenschutz = graphql`
  query {
    strapiDatenschutz {
      content
    }
  }
`;

export default DatenschutzPage;
